import "core-js/stable";
import "date-input-polyfill";
import "../scss/Zeridian.scss";

import { StripeCustomElement } from "./customElements/Stripe";
import XTermCustomElement from "./customElements/XTerm";
import ProvinceSelectorElement from "./customElements/ProvinceSelector";

// We use webpack config to make this pick the right Elm app file (either a bazel-compiled elmApp.js or a elm-webpack-loader one through the regular Main.elm file)
// See `resolve.alias` at  webpack.prod.js/webpack.dev.js.
import { Elm } from "elmApp";

/* CUSTOM ELEMENTS */

customElements.define("stripe-elements", StripeCustomElement);
customElements.define("x-term", XTermCustomElement);
customElements.define("address-state-selector", ProvinceSelectorElement);

const ROLLBAR_TOKEN = window.ROLLBAR_TOKEN || "";
const ROLLBAR_ENV = window.ROLLBAR_ENV || "";

window.STRIPE_TOKEN = STRIPE_TOKEN || window.STRIPE_TOKEN;

const elmApp = Elm.Main.init({
  flags: {
    rollbarToken: ROLLBAR_TOKEN,
    rollbarEnv: ROLLBAR_ENV,
    platform: navigator.platform,
  },
});

let isProduction = ROLLBAR_ENV == "production";

/* PORT SUBSCRIPTIONS */

const setIsImpersonating = (isImpersonating) => {
  window.ASIsImpersonating = isImpersonating;
};

elmApp.ports.setIsImpersonating.subscribe(setIsImpersonating);

elmApp.ports.recordUser.subscribe((user) => {
  // We need to set the impersonation flag here so we don't log actions for a
  // user that an ActiveState Admin is actually peforming on their behalf.
  setIsImpersonating(user.isImpersonating);

  if (window.ASIsImpersonating) {
    return;
  }

  // datalayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    activestate: {
      userId: user.userID,
      isPaidUser: user.isPaidUser,
    },
  });

  // heap (loaded async, only available in prod)
  if (window.heap) {
    window.heap.identify(user.userID);
    window.heap.addUserProperties({
      email: user.email,
      "user-startdate": user.added,
      "user-highest-tier": user.highestTier,
    });
  }
});

elmApp.ports.stopRecordingUser.subscribe(() => {
  let existing = window.dataLayer;
  if (existing) {
    window.dataLayer = existing.filter((x) => !Object.hasOwn(x, "userId"));
  }

  // heap (loaded async, only available in prod)
  if (window.heap) {
    window.heap.resetIdentity();
  }
});

elmApp.ports.copyToClipboard.subscribe((input) => {
  let text = input.text;
  let copyID = input.copyID;
  navigator.clipboard.writeText(text).then(
    function () {
      setTimeout(() => {
        elmApp.ports.copyStatus.send({ copyID: copyID, status: true });
      }, 10); // Timing hack to make this workd
    },
    function () {
      console.log("Unable to copy to clipboard: ", text, ", ", copyID);
    }
  );
});

/** Sets a query parameter on the current URL
 */
elmApp.ports.setUrlParameter.subscribe((details) => {
  let [name, value] = details;
  let url = new URL(window.location.href);

  if (window.URLSearchParams) {
    let params = new URLSearchParams(url.search);
    params.set(name, value);
    url.search = params;
    window.history.replaceState("", "", url.href);
  }
});

/** Remove a query parameter from the current URL
 */
elmApp.ports.removeUrlParameter.subscribe((paramName) => {
  if (window.URLSearchParams) {
    let url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    window.history.replaceState("", "", url.href);
  }
});

elmApp.ports.setCSRFCookie.subscribe(() => {
  let token = generateToken(20);
  document.cookie = "csrf=" + token + ";path=/;max-age=300";
  elmApp.ports.csrfCookieSet.send(token);
});

// Sets a localStorage value
if (elmApp.ports.setLocalStorage) {
  elmApp.ports.setLocalStorage.subscribe((details) => {
    window.localStorage.setItem(details.key, details.value);
  });
}

// Clears a localStorage value
if (elmApp.ports.clearLocalStorage) {
  elmApp.ports.clearLocalStorage.subscribe((name) => {
    window.localStorage.removeItem(name);
  });
}

// Gets localStorage value and sends to Port
// Returns "undefined" to handle "null"

if (elmApp.ports.requestLocalStorage && elmApp.ports.receiveLocalStorage) {
  elmApp.ports.requestLocalStorage.subscribe((key) => {
    let value = window.localStorage.getItem(key) || "undefined";
    elmApp.ports.receiveLocalStorage.send({ key: key, value: value });
  });
}

function generateToken(len) {
  // get some random (or "random") bytes
  var bytes = new Uint8Array((len || 20) / 2);
  if (window.crypto && window.crypto.getRandomValues) {
    window.crypto.getRandomValues(bytes);
  } else {
    bytes.map(() => Math.floor(Math.random() * 255));
  }
  // turn each byte into its zero-padded hex representation;
  // can't use bytes.map() because we're changing type, can't use
  // Array.from() because IE doesn't support it
  var token = "";
  for (const i of bytes) {
    const hex = Number(i).toString(16);
    token += ("0" + hex).slice(-2);
  }
  return token;
}

/*
  So we don't have to repeat the code that checks to see if hubspot is ready for
  every call to the hubspot api
*/
let _callHubspotConsoleAPI = (funcName) => {
  let callFunc = () => {
    try {
      if (window.HubSpotConversations.widget[funcName]) {
        window.HubSpotConversations.widget[funcName]();
      } else {
        console.warn(
          "No attribute'" + funcName + "' on 'window.HubSpotConversations'"
        );
      }
    } catch (e) {
      console.error(
        "Failed to calling 'window.HubSpotConversations.widget." +
          funcName +
          "()'."
      );
      console.error(e);
    }
  };
  /*
    Hubspot can be slow to load, If external API methods are already available, use them.
  */
  if (window.HubSpotConversations) {
    callFunc();
  } else {
    /*
      Otherwise, use hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady.push(callFunc);
  }
};

// Open the HubSpot bubble chat
// Hubspot will not work in any env but prod: https://activestatef.atlassian.net/browse/DS-1096
elmApp.ports.openHubSpotChat.subscribe(() => {
  _callHubspotConsoleAPI("open");
});

// Load the HubSpot bubble onto the screen
elmApp.ports.loadHubSpotChat.subscribe(() => {
  _callHubspotConsoleAPI("load");
});

// Remove the HubSpot bubble.
// Requires that you call `load` if you want it back.
elmApp.ports.removeHubSpotChat.subscribe(() => {
  _callHubspotConsoleAPI("remove");
});

// Send a "HubSpot is ready" event once it has loaded
window.hsConversationsOnReady = [
  () => {
    elmApp.ports.hubSpotChatLoaded.send(true);
  },
];

let setUpGoogleAnalyticsReadyFunction = () => {
  window.ga(() => {
    // In our testing the client ids on all trackers were the same value.
    let gaData = { clientId: window.ga.getAll()[0].get("clientId") };
    elmApp.ports.googleAnalyticsReady.send(gaData);
  });
};

let trySetGoogleAnalyticsReadyFunction = (count) => {
  clearInterval(garIntervalID);
  if (typeof ga === "function") {
    setUpGoogleAnalyticsReadyFunction();
  } else {
    // Give up after a few seconds. If `ga` hasn't appeared yet, it's
    // probably not going to.
    if (count > 5) return;

    garIntervalID = setInterval(
      trySetGoogleAnalyticsReadyFunction,
      500 * count,
      count * 2
    );
  }
};
var garIntervalID = setInterval(trySetGoogleAnalyticsReadyFunction, 500, 1);

elmApp.ports.startBuildProgress.subscribe(
  ([url, [partitionKey, partitionKeyValue]]) => {
    let socket = new WebSocket(url);

    socket.addEventListener("open", () => {
      socket.send(JSON.stringify({ [partitionKey]: partitionKeyValue }));
    });

    socket.addEventListener("message", (event) => {
      elmApp.ports.receiveBuildLogStreamerEvent.send(event.data);
    });
  }
);

// Mapping: PlatformID -> ArtifactID -> Socket
let artifactSockets = {};
elmApp.ports.startArtifactProgress.subscribe(
  ({ url, platformID, artifactID }) => {
    let socket = new WebSocket(url);

    artifactSockets[platformID] = artifactSockets[platformID] || {};
    artifactSockets[platformID][artifactID] = socket;

    socket.addEventListener("open", () => {
      socket.send(JSON.stringify({ artifactID }));
    });

    socket.addEventListener("message", (event) => {
      elmApp.ports.receiveBuildLogStreamerEvent.send(event.data);
    });
  }
);

elmApp.ports.stopArtifactProgress.subscribe(({ platformID, artifactID }) => {
  let socket =
    artifactSockets[platformID] && artifactSockets[platformID][artifactID];

  if (socket) {
    socket.close();
  }
});

window.addEventListener(
  "storage",
  function (event) {
    if (event.storageArea === localStorage && event.key === "session") {
      elmApp.ports.onSessionChange.send(event.newValue);
    }
  },
  false
);

// Sends an analytics event (Google Analytics, Heap, etc)
// as long as we're on production.
elmApp.ports.sendAnalyticsEvent.subscribe((details) => {
  // Google Analytics: Loop over all trackers and send the event
  // to each unique tracker only once since we have two separate
  // GA properties now.
  let trackersSentTo = []; // Keeps track of where we sent to already.
  if (isProduction && !window.ASIsImpersonating) {
    try {
      let trackers = window.ga.getAll();

      for (var i = 0; i < trackers.length; i++) {
        let tracker = trackers[i];
        let trackerID = tracker.get("trackingId");
        let trackerName = tracker.get("name");

        if (trackerName && trackersSentTo.indexOf(trackerID) < 0) {
          window.ga(trackerName + ".send", {
            hitType: "event",
            eventName: details.name, // String
            eventCategory: details.category, // String
            eventAction: details.action, // String
            eventLabel: details.label, // String
            eventValue: details.value, // Int
            eventValue2: details.value2, // Int
          });
          trackersSentTo.push(trackerID);
        }
      }
    } catch (err) {
      console.log("Unable to send Google Event, no tracker found.");
    }

    // Heap: loaded asynchronously in index.template
    // through google tag manager
    if (window.heap) {
      window.heap.track(details.name, {
        // String
        category: details.category, // String
        action: details.action, // String
        label: details.label, // String
        value: details.value, // Int
        value2: details.value2, // Int
      });
    }
  }
});

/* WALKME WIDGET */

const subscribeToTitleChanges = (callback) => {
  /* MutationObservers offer a simple API to watch for changes in the DOM.
   * Here, we're using one to subscribe to changes in the page title, to refresh the WalkMe UI widget.
   *
   * Browser support for this feature is good, see: https://stackoverflow.com/a/29540461 for more details.
   */
  return new MutationObserver(callback).observe(
    document.querySelector("title"),
    { childList: true }
  );
};

const refreshWalkMe = () => {
  if (window.WalkMeAPI !== undefined) {
    window.WalkMeAPI.triggerUIChange();
  }
};

subscribeToTitleChanges(refreshWalkMe);

// Scrolls to a specific element on the page. The location argument
// sets which part of the element we align the view to. Default is top.
elmApp.ports.scrollIntoView.subscribe(({ selector, location }) => {
  let args = { behavior: "smooth" };
  if (location) {
    args.block = location;
  }

  const cb = () => {
    let scrollToElement = document.querySelector(selector);
    if (scrollToElement) {
      scrollToElement.scrollIntoView(args);
    }
  };

  // We use requestIdleCallback, if available, for the use case where we
  // want to scroll to an element right after the page initally loads,
  // since the page will first be idle after all the code to create
  // elements has run for the first time.
  if (window.requestIdleCallback) {
    window.requestIdleCallback(cb);
  } else {
    // Fallback for Safari 16, etc.
    setTimeout(cb, 10);
  }
});

elmApp.ports.promptToDownload.subscribe(
  ({ mediaType, payload, suggestedFilename }) => {
    var anchor = document.createElement("a");
    anchor.href = "data:" + mediaType + ";base64," + window.btoa(payload);
    if (suggestedFilename) {
      anchor.download = suggestedFilename;
    }

    anchor.click();
  }
);

elmApp.ports.openPopupWithContent.subscribe((text) => {
  const newWindow = window.open("", "Logs", "popup=yes");
  newWindow.document.title = "Logs";
  newWindow.document.body.innerHTML = text;
});

elmApp.ports.downloadFileWithText.subscribe(({ filename, content }) => {
  const link = document.createElement("a");
  link.download = filename;

  const blob = new Blob([content], { type: "text/plain" });
  link.href = window.URL.createObjectURL(blob);
  link.click();
});

let experimentTimeouts = {};
const experimentTimeoutMS = 2000;

// Requests the variation for a specific Google Optimize experiment.
// This port may not be implemented Elm side, and in that case would be
// optimized away.
if (elmApp.ports.requestExperimentValue) {
  elmApp.ports.requestExperimentValue.subscribe((experimentID) => {
    if (
      typeof VWO == "undefined" ||
      !VWO.push ||
      typeof _vwo_exp == "undefined" ||
      typeof _vwo_exp[experimentID] == "undefined"
    ) {
      window.VWO = window.VWO || [];

      !isProduction &&
        console.log(
          "VWO has not loaded yet. This is expected as it is loaded asynchronously, but if you experience issues please make sure Adblock and Tracking Protection are OFF."
        );
    }

    // Load experiment data from cookies. If this doesn't work, make sure the experiment has started in VWO
    // https://developer.mozilla.org/en-US/docs/web/api/document/cookie#example_2_get_a_sample_cookie_named_test2
    let variationID = document.cookie
      .split("; ")
      .find((row) => row.startsWith("_vis_opt_exp_" + experimentID + "_combi="))
      ?.split("=")[1];

    // If we have the value stored in localStorage, then return that.
    // Otherwise, ask Google Optimize for it (with a time limit so we don't wait forever).
    if (variationID) {
      !isProduction &&
        console.log("Found experiment in cookie: ", experimentID, variationID);
      sendExperimentValue(experimentID, variationID);
    } else {
      !isProduction &&
        console.log(
          "Could not find experiment in localStorage: ",
          experimentID
        );

      // Callback to clean things up if we don't get an experiment value back
      // from VWO
      experimentTimeouts[experimentID] = setTimeout(() => {
        sendExperimentValue(experimentID, null);
        !isProduction &&
          console.log(
            `Could not find experiment in optimize: ${experimentID}. If this is unexpected, make sure your adblocker is turned off.`
          );
      }, experimentTimeoutMS);

      VWO.push([
        "onEventReceive",
        "vA",
        function (data) {
          // To fetch A/B test id
          let experimentID = data[1];
          // To fetch A/B test active variation ID
          let variationID = data[2];
          if (
            typeof _vwo_exp[experimentID].comb_n[variationID] !== "undefined" &&
            ["VISUAL_AB", "VISUAL", "SPLIT_URL", "SURVEY"].indexOf(
              _vwo_exp[experimentID].type
            ) > -1
          ) {
            experimentCallback(experimentID, variationID);
          }
        },
      ]);
    }
  });
}

// Requests the variation for a specific Google Optimize experiment value
// that is already stored in localStorage.
// This port may not be implemented Elm side, and in that case would be
// optimized away.
if (elmApp.ports.requestStoredExperimentValue) {
  elmApp.ports.requestStoredExperimentValue.subscribe((experimentName) => {
    let storedValue = window.localStorage.getItem(
      "experiment-" + experimentName
    );
    if (storedValue) {
      !isProduction &&
        console.log(
          "Found experiment in localStorage: ",
          experimentName,
          storedValue
        );
      sendExperimentValue(experimentName, storedValue);
    } else {
      !isProduction &&
        console.log(
          "Could not find experiment in localStorage: ",
          experimentName
        );
      sendExperimentValue(experimentName, null);
    }
  });
}

// When we get the variation value from Google Optimize,
// destroy the timeout we set in case the call failed
// and send the value back to the front end.
const experimentCallback = (experimentID, variationID) => {
  if (experimentTimeouts[experimentID]) {
    !isProduction &&
      console.log("Found experiment VWO session: ", experimentID, variationID);
    sendExperimentValue(experimentID, variationID);
    destroyTimeout(experimentID);
  }
};

const destroyTimeout = (timeoutName) => {
  clearTimeout(experimentTimeouts[timeoutName]);
  experimentTimeouts[timeoutName] = null;
};

// Sends the variation value back to our front end, and stores
// the value in localStorage for later retrieval.
const sendExperimentValue = (experimentID, variationID) => {
  let sendValue = null;

  if (!variationID || isNaN(parseInt(variationID, 10))) {
    !isProduction &&
      console.log("Experiment value is not a number: ", variationID);
    return;
  } else {
    sendValue = parseInt(variationID, 10);
  }

  elmApp.ports.receiveExperimentValue.send({
    id: experimentID,
    variation: sendValue,
  });
};
